// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hundarna-js": () => import("./../../../src/pages/hundarna.js" /* webpackChunkName: "component---src-pages-hundarna-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-planer-och-kontakt-js": () => import("./../../../src/pages/planer-och-kontakt.js" /* webpackChunkName: "component---src-pages-planer-och-kontakt-js" */),
  "component---src-pages-till-minne-js": () => import("./../../../src/pages/till-minne.js" /* webpackChunkName: "component---src-pages-till-minne-js" */),
  "component---src-pages-valpar-js": () => import("./../../../src/pages/valpar.js" /* webpackChunkName: "component---src-pages-valpar-js" */)
}

